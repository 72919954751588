window.$ = require('jquery');
window.jQuery = window.$;

$( document ).ready(function() {

	// Mobile menu
	$('.mobile-menu').on('click', function(){

		$(this).toggleClass('is-open');
		$('.top.right').toggleClass('is-open');
	});

	$('.top.right').on('click', function(){

		if ( $(this).hasClass('is-open') ) {

			$(this).removeClass('is-open');
			$('.mobile-menu').removeClass('is-open');
		}
	});
})